<template>
  <div>

    <h1 class="text-2xl font-bold mb-5">Pending Bank Account Verifications</h1>

    <div class="border border-solid border-blue-200 rounded overflow-hidden">
      <datatable
        :ajax="true"
        :ajaxPagination="true"
        :url="users.url"
        :columns="users.columns"
        :loading="users.loading"
        :query="users.query"
        :onClick="click"
        ref="table"
      />
    </div>

    <modals-user
      :selected-user="users.selected"
      ref="userModal"
      @delete="userDeleted"
      @close="userModalClosed"
    />

  </div>
</template>
<script>
  export default {
    data() {
      return {
        users: this.$options.resource([], {
          url: `${this.$baseurl}/admin/personal/bank-accounts/pending`,
          query: '',
          selected: null,
          columns: [
            {
              name: 'name',
              th: 'User',
              render: user => `${user.name} ${user.last_name}`
            },
            {
              name: 'bank_account_bank',
              th: 'Bank',
              render: user => user.profile?.trial_bank_code
            },
            {
              name: 'bank_account_no',
              th: 'Account Number',
              render: user => user.profile?.trial_account_no
            },
            {
              name: 'bank_account_name',
              th: 'Account Name',
              render: user => {
                return user.profile?.trial_bank_account_name || 'This account probably doesn\'t exist'
              }
            }
          ]
        })
      }
    },
    methods: {
      click(user) {
        this.users.selected = user;
        this.$refs.userModal.open();
      },
      reloadTable() {
        this.$refs.table.loadAjaxData()
      },
      userDeleted() {
        this.reloadTable();
      },
      userModalClosed() {
        this.users.selected = null;
      },
    }
  }
</script>
